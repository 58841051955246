import { z } from "zod";
export const PaginationQuerySchema = z.object({
    cursor: z.string().optional(),
    limit: z
        .number({ coerce: true })
        .int()
        .min(1, "Limit must be at least 1")
        .max(1000, "Limit cannot exceed 1000")
        .optional(),
});
export const createPaginationResponseSchema = (dataSchema) => z.object({
    data: z.array(dataSchema),
    pagination: z.object({
        next: z.string().nullable(),
    }),
});
