import { AppShell, Divider, Title } from "@mantine/core";

type NavigationSectionProps = {
  title?: string;
  grow?: boolean;
  divider?: boolean;
  children: React.ReactNode;
};

export function NavigationSection({
  title,
  grow = false,
  divider = true,
  children,
}: NavigationSectionProps) {
  return (
    <AppShell.Section grow={grow}>
      {title ? (
        <Title size="h5" order={3} mx="sm">
          {title}
        </Title>
      ) : null}
      {children}
      {divider ? <Divider mx="md" my="md" /> : null}
    </AppShell.Section>
  );
}
