import { z } from "zod";
import { TagNameSchema, TagSchema } from "../tags.js";
import { createPaginationResponseSchema } from "../pagination.js";
export const ScriptBookmarkSchema = z.object({
    id: z.string().uuid(),
    createdAt: z.string(),
});
export const ScriptSchema = z.object({
    id: z.string().uuid(),
    title: z.string(),
    description: z.string(),
    dialogCount: z.number(),
    triggerCoveragePercentage: z.number(),
    createdBy: z.object({
        id: z.string(),
        username: z.string(),
    }),
    published: z.boolean(),
    createdAt: z.string(),
    updatedAt: z.string(),
    bookmark: ScriptBookmarkSchema.optional(),
    vote: z.number().optional(),
    rating: z.object({
        votes: z.number(),
        score: z.number().optional(),
    }),
    tags: TagSchema.array(),
});
export const ScriptsResponseSchema = createPaginationResponseSchema(ScriptSchema);
export const CreateScriptSchema = z.object({
    title: z
        .string()
        .min(5, "Title must be at least 5 characters long.")
        .max(30, "Title cannot exceed 30 characters."),
    description: z
        .string()
        .min(10, "Description must be at least 10 characters long.")
        .max(500, "Description cannot exceed 500 characters."),
    published: z.boolean(),
});
export const UpdateScriptSchema = CreateScriptSchema.pick({
    title: true,
    description: true,
    published: true,
})
    .extend({
    tags: TagNameSchema.array(),
})
    .partial();
export const SCRIPT_ID = {
    COUNTDOWN: "8587d760-14cd-412f-9584-93fa8a9f4991",
    DEFAULT: "d668e7e6-38a8-4b7b-bcc0-496faac60498",
};
