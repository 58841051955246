import "@mantine/core/styles.css";
import { notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import * as ReactQuery from "@tanstack/react-query";
import { QueryCache } from "@tanstack/react-query";
import type * as React from "react";

export const queryClient = new ReactQuery.QueryClient({
  queryCache: new QueryCache({
    onError(error, query) {
      if (query.meta?.errorDescription) {
        notifications.show({
          title: error.message,
          message: query.meta.errorDescription as string,
          color: "red",
          autoClose: 10000,
        });
      }
    },
  }),
});

type QueryProviderProps = { children: React.ReactNode };

export function QueryClientProvider({ children }: QueryProviderProps) {
  return (
    <ReactQuery.QueryClientProvider client={queryClient}>
      {children}
    </ReactQuery.QueryClientProvider>
  );
}
