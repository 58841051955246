import { z } from "zod";
export const VoiceSchema = z.enum([
    "chloe",
    "april",
    // "kanako",
    "morrigan",
    "luca",
    "wyatt",
    "betsy",
    "nora",
]);
export const voices = VoiceSchema.options;
