// @ts-expect-error Forced to use v2 due to Lovense.
import io from "socket.io-client";
import { getPercentFromTempo } from "@fapinstructor/math";
import { createObserver } from "@fapinstructor/common";
import { LovenseQrCodeSchema } from "./schemas/lovense-qr-code-schema.js";
import { getActionValue } from "./utils/get-action-value.js";
import { toyType, deviceFunctions, } from "./constants/device-info.js";
import { getLovenseSocketUrl } from "./api/get-lovense-socket-url.js";
import { LovenseUpdateAppStatusSchema } from "./schemas/lovense-update-app-status-schema.js";
import { LovenseUpdateDeviceInfoSchema } from "./schemas/lovense-update-device-info-schema.js";
export class Lovense {
    ws;
    status = "disconnected";
    toys = [];
    onStatus = createObserver();
    onQrCode = createObserver();
    onToy = createObserver();
    onError = createObserver();
    get connected() {
        return this.status === "connected";
    }
    async connect(token) {
        if (this.ws || this.status !== "disconnected") {
            return;
        }
        this.status = "connecting";
        try {
            // Create the web socket connection.
            const { socketIoUrl, socketIoPath } = await getLovenseSocketUrl(token);
            this.ws = io(socketIoUrl, {
                path: socketIoPath,
                transports: ["websocket"],
                autoConnect: true,
            });
            // Wait until Lovense tells us their ready.
            const isOnline = await new Promise((resolve) => {
                this.ws.once("basicapi_update_app_online_tc", (payload) => {
                    const { status } = LovenseUpdateAppStatusSchema.parse(JSON.parse(payload));
                    resolve(status === 1);
                });
            });
            this.status = isOnline ? "connected" : "disconnected";
            this.onStatus.publish(isOnline);
            this.onQrCode.publish(undefined);
            this.attachEventListeners();
        }
        catch (error) {
            this.status = "disconnected";
            this.onError.publish(error);
        }
    }
    attachEventListeners() {
        if (!this.ws) {
            return;
        }
        this.ws.on("basicapi_update_app_status_tc", (payload) => {
            const { status } = LovenseUpdateAppStatusSchema.parse(JSON.parse(payload));
            this.status = status === 1 ? "connected" : "disconnected";
            this.onStatus.publish(status === 1);
            this.onQrCode.publish(undefined);
        });
        this.ws.on("basicapi_get_qrcode_tc", (response) => {
            const { data: { code, qrcodeUrl }, } = LovenseQrCodeSchema.parse(JSON.parse(response));
            const qr = { code, qrcodeUrl };
            this.onQrCode.publish(qr);
        });
        // Received device information.
        this.ws.on("basicapi_update_device_info_tc", (payload) => {
            const { toyList } = LovenseUpdateDeviceInfoSchema.parse(JSON.parse(payload));
            this.status = "connected";
            this.toys = toyList;
            this.onToy.publish(toyList);
            this.onStatus.publish(true);
        });
    }
    generateQrCode() {
        if (!this.ws) {
            return;
        }
        this.ws.emit("basicapi_get_qrcode_ts", {});
    }
    sendToyCommand(...commands) {
        if (!this.ws) {
            return;
        }
        for (const command of commands) {
            this.ws.emit("basicapi_send_toy_command_ts", {
                command: "Function",
                timeSec: 0,
                apiVer: 1,
                ...command,
            });
        }
    }
    generateToyCommandByToyType(type, percent) {
        const allToys = this.toys;
        const onlineToys = this.filterToyByStatus(allToys, true);
        const toys = this.filterToyByType(onlineToys, type);
        const commands = toys.map((toy) => {
            const action = this.getActionByFunctionName(toy.toyType, percent);
            const command = { toy: toy.id, action };
            return command;
        });
        return commands;
    }
    getActionByFunctionName(toy, percent) {
        const functions = deviceFunctions[toy];
        return functions
            .map((name) => `${name}:${getActionValue(percent, name)}`)
            .join(",");
    }
    filterToyByStatus(toys, connected) {
        return toys.filter((toy) => toy.connected === connected);
    }
    filterToyByType(toys, type) {
        const allToyByType = toyType[type];
        return toys.filter((toy) => allToyByType.includes(toy.toyType));
    }
    tempo(type, tempo) {
        const percent = getPercentFromTempo([0, 240], tempo);
        const commands = this.generateToyCommandByToyType(type, percent);
        this.sendToyCommand(...commands);
    }
    vibrate(type, percent) {
        const commands = this.generateToyCommandByToyType(type, percent);
        this.sendToyCommand(...commands);
    }
}
