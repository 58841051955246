import { z } from "zod";
export const TagNameSchema = z
    .string()
    .regex(/^[a-z0-9_-]+$/, {
    message: "Tag can only contain lower-case letters, numbers, dashes, or underscores.",
})
    .min(1, { message: "Tag must contain at least one character." })
    .max(280, { message: "Tag cannot exceed 280 characters." });
export const TagSchema = z.object({
    id: z.number(),
    name: TagNameSchema,
});
export const TagsResponseSchema = TagSchema.array();
