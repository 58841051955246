import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import common from "../locales/en/common.json";
import triggers from "../locales/en/triggers.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    resources: {
      en: {
        common,
        triggers,
      },
    },
    ns: ["common", "triggers"],
    defaultNS: ["common"],
  });

export default i18n;
