import { RedirectToSignIn, SignedIn, SignedOut } from "@clerk/clerk-react";
import { RedirectNonPatrons } from "@/components/redirect-non-patrons";
import { AccountSettingsProvider } from "@/providers/account-settings-provider";
import { Outlet } from "react-router-dom";
import type { MembershipTier } from "@fapinstructor/common";

type PrivateRouteProps = {
  requiredMembershipTier: MembershipTier;
};

export function PrivateRoute({ requiredMembershipTier }: PrivateRouteProps) {
  return (
    <>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
      <SignedIn>
        {requiredMembershipTier === "free" ? (
          <>
            {/* User is authenticated, but doesn't require a membership. */}
            <Outlet />
          </>
        ) : (
          <RedirectNonPatrons requiredMembershipTier={requiredMembershipTier}>
            <AccountSettingsProvider>
              <Outlet />
            </AccountSettingsProvider>
          </RedirectNonPatrons>
        )}
      </SignedIn>
    </>
  );
}
