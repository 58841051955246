import { LoadingOverlay } from "@mantine/core";
import { Navigate } from "react-router-dom";
import { usePatronStatus } from "@/api/fapinstructor/patreon/get-patreon-status";
import { getErrorMessage } from "@/utils/get-error-message";
import {
  hasRequiredMembership,
  type MembershipTier,
} from "@fapinstructor/common";
import PageNotFound from "@/pages/page-not-found";

type RedirectNonPatronsProps = {
  children: React.ReactNode;
  requiredMembershipTier: MembershipTier;
};

export function RedirectNonPatrons({
  children,
  requiredMembershipTier,
}: RedirectNonPatronsProps) {
  const patronStatusQuery = usePatronStatus();

  if (patronStatusQuery.isFetching) {
    return (
      <LoadingOverlay
        visible={true}
        zIndex={1000}
        overlayProps={{ opacity: 1, blur: 1 }}
        loaderProps={{ color: "pink", type: "bars" }}
      />
    );
  }

  if (patronStatusQuery.isError) {
    return (
      <Navigate
        to={`/error?title=Unable to determine patron status&message=${getErrorMessage(patronStatusQuery.error)}`}
      />
    );
  }
  const currentMembershipTier =
    patronStatusQuery.data?.membershipTier ?? "free";

  // Redirect to sign-up page for free members.
  if (currentMembershipTier === "free") {
    return <Navigate to="/patreon/signup" />;
  }

  // Return 404 for pages the user doesn't have access to.
  if (!hasRequiredMembership(currentMembershipTier, requiredMembershipTier)) {
    return <PageNotFound />;
  }

  return <>{children}</>;
}
