import { z } from "zod";
import { unsplashPhotoSchema } from "./unsplash.js";
import { mediaTypeFilterSchema } from "./media.js";
import { VoiceSchema } from "./script/speech.js";
export const mediaConfigSchema = z.object({
    tags: z.array(z.string()).min(1, { message: "Must enter at least one tag" }),
    mediaTypes: z
        .array(mediaTypeFilterSchema)
        .min(1, { message: "Must select at least one format" }),
});
export const gameSchema = z.object({
    id: z.string(),
    createdBy: z.object({
        id: z.string(),
        username: z.string(),
    }),
    events: z.array(z.unknown()),
    createdAt: z.string(),
    updatedAt: z.string(),
    name: z.string(),
    description: z.string(),
    shared: z.boolean(),
    duration: z.number(),
    splash: unsplashPhotoSchema,
    media: mediaConfigSchema,
    voice: VoiceSchema,
});
export const gamesSchema = z.array(gameSchema);
export const createGameParamsSchema = z.object({
    events: z.array(z.unknown()),
    name: z.string().min(5).max(30),
    description: z.string().min(10).max(500),
    shared: z.boolean(),
    duration: z.number(),
    splash: unsplashPhotoSchema,
    media: mediaConfigSchema,
});
